import { useState, useEffect } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import Box from "@material-ui/core/Box";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;
const MAPBOX_MAP_STYLE = process.env.REACT_APP_MAPBOX_MAP_STYLE;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  fill: "#6F9CEB",
  stroke: "none",
};

function Pin(props) {
  const { size = 20 } = props;

  return (
    <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
      <path d={ICON} />
    </svg>
  );
}

const SelectGeoCoordinates = ({
  onChange,
  latitude,
  longitude,
  initialInputValue,
}) => {
  const [marker, setMarker] = useState({
    latitude,
    longitude,
  });
  const [viewport, setViewport] = useState({
    latitude,
    longitude,
    zoom: 8,
    bearing: 0,
    pitch: 0,
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [address, setAddress] = useState("");
  const { t } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (isLoaded && window.google && window.google.maps) {
      const token = new window.google.maps.places.AutocompleteSessionToken();
      setSessionToken(token);
    }
  }, [isLoaded]);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null && window.google && window.google.maps) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;

      let street = "";
      let street_number = "";
      let city = "";
      let country = "";
      let zipcode = "";

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          street_number = component.long_name;
        }
        if (types.includes("route")) {
          street = component.long_name;
        }
        if (types.includes("locality")) {
          city = component.long_name;
        }
        if (types.includes("country")) {
          country = component.short_name;
        }
        if (types.includes("postal_code")) {
          zipcode = component.long_name;
        }
      });

      console.log("addressComponents: ", addressComponents);

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      console.log(
        latitude,
        longitude,
        street,
        street_number,
        zipcode,
        city,
        country
      );

      onChange({
        latitude,
        longitude,
        street,
        street_number,
        zipcode,
        city,
        country,
      });
      setMarker({
        latitude,
        longitude,
      });
      setViewport({
        ...viewport,
        latitude,
        longitude,
        zoom: 15,
      });

      setAddress(formattedAddress);
      setSessionToken(null);
    }
  };

  const handleInputChange = (event) => {
    setAddress(event.target.value);
  };

  useEffect(() => {
    setAddress(initialInputValue || "");
  }, [initialInputValue]);

  const onMarkerDragEnd = (event) => {
    onChange({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });
    setMarker({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });
  };

  const handleClick = (e) => {
    onChange({
      longitude: e.lngLat[0],
      latitude: e.lngLat[1],
    });
    setMarker({
      longitude: e.lngLat[0],
      latitude: e.lngLat[1],
    });
  };

  return (
    <Box>
      {isLoaded ? (
        <>
          <Box my={2}>
            <Alert severity="info">
              {t(
                "Search a place with the search box or drag the pin around to the desired location"
              )}
            </Alert>
          </Box>
          <FormControl fullWidth margin="none">
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              options={{ sessionToken }}
            >
              <TextField
                label={t("Search location")}
                variant="outlined"
                fullWidth
                placeholder="Search for places"
                value={address}
                onChange={handleInputChange}
              />
            </Autocomplete>
          </FormControl>
        </>
      ) : (
        <div>Loading...</div>
      )}
      <ReactMapGL
        width={"100%"}
        height={"500px"}
        {...viewport}
        onViewportChange={setViewport}
        mapStyle={MAPBOX_MAP_STYLE}
        mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
        onClick={handleClick}
      >
        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
          offsetTop={-20}
          offsetLeft={-10}
          draggable
          onDragEnd={onMarkerDragEnd}
        >
          <Pin size={30} />
        </Marker>
        <div
          style={{ position: "absolute", top: "0", left: 0, padding: "10px" }}
        >
          <NavigationControl />
        </div>
      </ReactMapGL>
    </Box>
  );
};

export default SelectGeoCoordinates;
